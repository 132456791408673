import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { BsPencilSquare, BsSave, BsTrash, BsXLg } from 'react-icons/bs';
import { showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION, getCurrentIsoDate } from '../../../common';
import Asterisk, { AsteriskText } from '../../../components/Asterisk';
import WithStaticOptions, { IWithStaticOptions } from '../../../components/hoc/withStaticOptions';
import TextIcon from '../../../components/textIcon';
import { useFormFields } from '../../../hooks';
import { TSourceReliabilityOptions } from '../../../interfaces/dict';
import { ISourceBase } from '../../../interfaces/sources';
import { selectAppStore, useAppSelector } from '../../../store';
import { getDataForSave } from '../../../utils';
import CountrySelector from '../../controls/CountrySelector';
import DangerousListItem from '../../dangerous/DangerousListItem';
import DangerousZone from '../../dangerous/DangerousZone';
import { useSanitizedDomainQuery, useSourceMutation } from '../../services/sources';

const DEFAULT_SOURCE: ISourceBase = {
	id: 0,
	title: '',
	base_domain: '',
	country: 'UKR',
	description: '',
	reliability: 'F',
	update_date: '',
	findable: true,
};

// ! При зміні цього виразу Ви маєте також змінити аналогічний вираз нижче у полі домену
// Рядок не уніфіковано, тому що при намаганні уніфікації виникає помилка невірного виразу в полі домену
const reDomainString =
	/^[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9][a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9-]{0,61}[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9]?(?:\.[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9-]{2,})+$/u;
interface ISourceDialogProps extends IWithStaticOptions, React.AllHTMLAttributes<HTMLDivElement> {
	source?: ISourceBase;
	newDomain?: string; // Якщо передано, то джерело передає користувач
	onClose: (sourceId?: number) => void;
}
const SourceDialog = ({ source, onClose, newDomain, staticOptions: { SOURCE_RELIABILITY } }: ISourceDialogProps) => {
	const inserting = source === undefined;
	const { data, isSuccess } = useSanitizedDomainQuery(newDomain || skipToken);
	const [initialData, setInitialData] = useState<ISourceBase>(
		() =>
			source ||
			({
				...DEFAULT_SOURCE,
				base_domain: data?.sanitizedDomain || newDomain || '',
				update_date: getCurrentIsoDate(),
			} as ISourceBase)
	);
	const { formFields, createChangeHandler, modified, setFormFields } = useFormFields(initialData);
	const refFirstControl = useRef<HTMLInputElement>(null);
	const [updateSource, updateSourceResult] = useSourceMutation();
	const { isAssetsAdmin } = useAppSelector(selectAppStore);
	const fullEdit = isAssetsAdmin && !newDomain;

	useEffect(() => {
		if (!source) return;
		setInitialData({ ...source });
	}, [source]);

	useEffect(() => {
		if (!updateSourceResult) return;
		const { isError, error, isSuccess, data } = updateSourceResult;
		if (isSuccess) return closeDialog(data.ID);
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateSourceResult]); // eslint-disable-line

	useEffect(() => {
		if (!data || !isSuccess || formFields.base_domain === data.sanitizedDomain) return;
		setFormFields({ ...formFields, base_domain: data.sanitizedDomain });
	}, [data, isSuccess, formFields, setFormFields]);

	const closeDialog = (sourceId?: number) => {
		onClose(sourceId);
	};

	const handleClose = async () => {
		if (modified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (!reDomainString.test(formFields['base_domain']))
			return showError("Не можливо додати джерело, позаяк введене доменне ім'я не є правильним");

		// if (!fullEdit && formFields.reliability === 'F') return showError('Ви маєте визначити якість джерела!');

		const dataToSave = getDataForSave<ISourceBase, 'id'>(inserting ? undefined : initialData, formFields, ['id']);
		updateSource({
			...dataToSave,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	const onCountryChange = (trigram: string) => {
		setFormFields({ ...formFields, country: trigram });
	};

	const onDeleteClick = async () => {
		let text = `Ви впевнені, що хочете видалити джерело «${formFields.title}»?`;
		if (formFields.update_date === getCurrentIsoDate())
			text +=
				' Схоже, що це джерело було створене сьогодні. Можливо, користувач, який його додав, ще працює над описовою частиною документа. Якщо Ви видалите це джерело, користувач може втратити результати своєї роботи. Якщо Ви не впевнені, чи є це джерело необхідним, краще почекайте до завтра.';
		if (!(await showPromiseConfirm(text))) return;
		await updateSource({ id: formFields.id, method: 'DELETE' });
	};

	// const onRefreshDateClick = async () => {
	// 	console.log(new Date().toLocaleString(), 'functionName');
	// };

	return (
		<Modal
			show
			centered
			size={fullEdit ? 'xl' : 'lg'}
			fullscreen="lg-down"
			onHide={handleClose}
			// className={fullEdit ? undefined : 'bg-secondary-subtle'}
			// contentClassName="bg-primary-subtle"
			backdrop={fullEdit ? undefined : 'static'}
			keyboard={fullEdit}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					Редагування джерела
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={onSubmit} id="fmSource">
					<Form.Group controlId="sourceTitle">
						<Form.Label>
							Назва
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Ви можете використати самоназву джерела"
							maxLength={200}
							value={formFields.title}
							onChange={createChangeHandler('title')}
							autoComplete="off"
							ref={refFirstControl}
							autoFocus
						/>
					</Form.Group>
					<Form.Group controlId="sourceURL" className="mt-3">
						<Form.Label>
							Домен
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="some-site.com"
							maxLength={100}
							value={formFields.base_domain}
							onChange={createChangeHandler('base_domain')}
							autoComplete="off"
							pattern="^[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9][a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9\-]{0,61}[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9]?(?:\.[a-zA-Zа-яА-ЯієїґўІЄЇҐЎ0-9\-]{2,})+$"
							disabled={!fullEdit}
							// readOnly={!fullEdit}
							// className={fullEdit ? undefined : 'bg-body-secondary'}
						/>
						{/* При звичайному пошуку та заміні замість застосованого вище складного регулярного виразу можливо було б використати вираз з Unicode subset такого вигляду:
							^[\p{L}\p{N}][\p{L}\p{N}\-]{0,61}[\p{L}\p{N}]?(?:\.[\p{L}\p{N}\-]{2,})+$
							(з обов'язковим флагом u). Але конкретно тут це використати не можна,
							бо використовується рядок. Більше тут: https://learn.javascript.ru/regexp-unicode */}
					</Form.Group>
					<Row className="mt-3 g-3">
						<CountrySelector
							trigram={formFields.country}
							containerClassName="col-xs-12 col-xl"
							onCountryChange={onCountryChange}
							required
						/>
						<Form.Group as={Col} xs={12} sm controlId="sourceReliability">
							<Form.Label>
								Якість джерела <Asterisk />
							</Form.Label>
							<Form.Select
								value={formFields.reliability}
								onChange={createChangeHandler('reliability')}
								disabled={!fullEdit}
							>
								{SOURCE_RELIABILITY &&
									Object.keys(SOURCE_RELIABILITY).map((key) => (
										<option key={key} value={key}>
											{key} — {SOURCE_RELIABILITY[key as TSourceReliabilityOptions]}
										</option>
									))}
							</Form.Select>
						</Form.Group>
						{fullEdit && (
							<Form.Group as={Col} xs="auto" controlId="sourceUpdateDate">
								<Form.Label>
									Дата оновлення якості <Asterisk />
								</Form.Label>
								<Form.Control
									type="date"
									value={formFields.update_date}
									onChange={createChangeHandler('update_date')}
									autoComplete="off"
								/>
							</Form.Group>
						)}
						{fullEdit && (
							<Form.Check
								type="checkbox"
								label="Може бути знайдене"
								id="sourceFindable"
								checked={formFields.findable}
								onChange={createChangeHandler('findable', 'boolean')}
								className="col-auto align-self-end"
							/>
						)}
					</Row>
					<Form.Group controlId="sourceDescription" className="mt-3">
						<Form.Label>Опис</Form.Label>
						<Form.Control
							as="textarea"
							rows={5}
							placeholder="Якщо вважаєте доцільним"
							value={formFields.description || ''}
							onChange={createChangeHandler('description')}
							autoComplete="off"
							className="no-resize"
							maxLength={4000}
						/>
					</Form.Group>
					{/* <fieldset className="mt-3">
						<legend>Додаткові дії</legend>
						<ListGroup>
							<DangerousListItem
								title="Оновити дату оцінки джерела."
								description="Дана операція змінить дату оновлення на поточну, лишаючи інші поля незмінними."
								disabled={modified}
								onClick={onRefreshDateClick}
								variant="outline-primary"
							>
								<TextIcon Icon={BsCalendarCheck} className="mt-n1">
									Оновити
								</TextIcon>
							</DangerousListItem>
						</ListGroup>
					</fieldset> */}
					{fullEdit && !inserting && (
						<DangerousZone className="mt-3">
							<DangerousListItem
								onClick={onDeleteClick}
								title="Видалити дане джерело."
								description={
									'Видалення можливе лише за умови відсутності приєднаних до даного джерела документів. Крім того, намагайтесь не видаляти джерела, створені в той же день. ' +
									DELETION_WARNING
								}
								disabled={updateSourceResult.isLoading}
							>
								<TextIcon Icon={BsTrash}>Видалити</TextIcon>
							</DangerousListItem>
						</DangerousZone>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<AsteriskText />
				{!inserting && <Badge bg="secondary">ID: {initialData.id}</Badge>}
				<div className="hstack gap-1">
					<Button disabled={!modified || updateSourceResult.isLoading} type="submit" form="fmSource">
						<TextIcon Icon={BsSave} className={updateSourceResult.isLoading ? 'animation-bounce' : undefined}>
							Зберегти
						</TextIcon>
					</Button>
					<Button variant="secondary" onClick={handleClose} type="button">
						<TextIcon Icon={BsXLg}>Закрити</TextIcon>
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default WithStaticOptions(SourceDialog);
