import { DEFAULTS, IUpdateResponse } from '../../common';
import { IEntityEditMethod, QUERY_TAGS } from '../../interfaces/common';
import { TDocumentSource } from '../../interfaces/document';
import { ISourceBase, ISourceList, TMutatingSource } from '../../interfaces/sources';
import { moriaApi } from './moriaApi';

// interface IUpdateResponse extends IDefaultHttpResponse {
// 	ID: number;
// }

export interface ISourcesRequestParams {
	term: string;
	order: keyof ISourceBase;
	pageNo: number;
}
export interface IRecentDocumentForSource {
	document_id: number;
	links_count: number;
	title: string;
	insertion_date: string;
	user_code: string;
}
const sourcesApi = moriaApi.injectEndpoints({
	endpoints: (builder) => ({
		sourceList: builder.query<ISourceList, ISourcesRequestParams>({
			query: (requestParams) => ({
				url: 'sources',
				method: DEFAULTS.httpMethod.post,
				body: {
					...requestParams,
					perPage: DEFAULTS.sourcesPerPage,
				},
			}),
			providesTags: (result) => {
				return [{ type: QUERY_TAGS.source, id: 'list' }].concat(
					(result?.sources.map(({ id }) => ({ type: QUERY_TAGS.source, id })) as any) ?? []
				);
				// return result
				// 	? [
				// 			...result.sources.ids.map((id) => ({ type: QUERY_TAGS.source, id })),
				// 			{ type: QUERY_TAGS.source, id: 'list' },
				// 	  ]
				// 	: [{ type: QUERY_TAGS.source, id: 'list' }];
			},
		}),
		recentDocumentsBySource: builder.query<IRecentDocumentForSource[], number>({
			query: (sourceId) => `source/${sourceId}/docs`,
		}),
		// getSource: builder.query<ISourceBase | null, number>({
		// 	query: (sourceId) => `source/${sourceId}`,
		// 	providesTags: (result, error, sourceId) => [{ type: QUERY_TAGS.source, id: sourceId }],
		// }),
		sourceFromDomain: builder.query<TDocumentSource | null, string>({
			query: (domain) => `source/by/domain/${encodeURIComponent(domain)}`,
		}),
		sanitizedDomain: builder.query<{ sanitizedDomain: string }, string>({
			query: (rawDomain) => `source/domain/sanitized/${encodeURIComponent(rawDomain)}`,
		}),
		source: builder.mutation<IUpdateResponse, TMutatingSource & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'edit/source',
				method,
				body: rest,
			}),
			invalidatesTags: (result, error, { id, method }) => [
				{ type: QUERY_TAGS.source, id: method === 'PUT' ? id : 'list' },
			],
		}),
	}),
});

export const {
	useSourceListQuery,
	useSourceMutation,
	useSourceFromDomainQuery,
	useRecentDocumentsBySourceQuery,
	useSanitizedDomainQuery,
} = sourcesApi;
